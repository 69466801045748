'use client'

import { useState } from 'react';
import Link from 'next/link';
import { Home, MessageCircle, Bell, Menu, User, LogOut, Search, LayoutDashboard, Users, Star, UserPlus, BookOpen, BookOpenCheck, Award, PlusCircle, Shield, CheckCircle, Lightbulb, HelpCircle, FileText, Mail, Briefcase, Settings } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/navigation';

import { useAuth } from '@/context/AuthContext';

// Definim categoriile în afara componentei
const menuCategories = [
  {
    title: "Explorează",
    items: [
      { name: "Acasă", href: "/", icon: Home },
      { name: "Caută Servicii", href: "/servicii", icon: Search },
      // { name: "Proiecte Recente", href: "/proiecte", icon: Briefcase },
      // { name: "Povești de Succes", href: "/testimoniale", icon: Star },
    ]
  },
  {
    title: "Pentru Profesioniști",
    items: [
      { name: "Devino Profesionist", href: "/register/worker", icon: UserPlus },
      { name: "Ghid de Început", href: "/ghid-profesionist", icon: BookOpen },
      // { name: "Centru de Resurse", href: "/centru-de-sfaturi", icon: BookOpenCheck },
      // { name: "Program de Certificare", href: "/certificare", icon: Award },
    ]
  },
  {
    title: "Pentru Clienți",
    items: [
      { name: "Postează un Proiect", href: "/new-post", icon: PlusCircle },
      { name: "Ghid de Siguranță", href: "/ghid-siguranta", icon: Shield },
      { name: "Garanția Serviciilor", href: "/garantie", icon: CheckCircle },
      { name: "Sfaturi pentru Renovare", href: "/sfaturi-renovare", icon: Lightbulb },
    ]
  },
  {
    title: "Despre Noi",
    items: [
      { name: "Cum Funcționează", href: "/cum-functioneaza", icon: HelpCircle },
      { name: "Blog", href: "/blog", icon: FileText },
      { name: "Contact", href: "/contact", icon: Mail }
    ]
  }
];

export default function MobileNavigation({ 
  unreadMessagesCount = 0, 
  unreadCount = 0 
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const router = useRouter();
  const { user, signOut } = useAuth();

  // Folosim menuCategories direct pentru categoriile de meniu
  const renderMenuCategories = () => {
    const userRole = user?.user_metadata?.role;
    
    // Pentru utilizatori neautentificați, arătăm toate categoriile
    if (!user) {
      return menuCategories;
    }

    // Pentru utilizatori autentificați, filtrăm categoriile bazate pe rol
    return menuCategories.filter(category => {
      if (category.title === "Pentru Profesioniști") {
        return userRole === 'worker';
      }
      if (category.title === "Pentru Clienți") {
        return userRole === 'client';
      }
      return true; // Păstrăm categoriile comune (Explorează și Despre Noi)
    });
  };

  // Acțiuni pentru useri neautentificați
  const guestActions = [
    { 
      icon: <Home className="w-6 h-6" />, 
      label: 'Acasă', 
      href: '/' 
    },
    { 
      icon: <Search className="w-6 h-6" />, 
      label: 'Servicii', 
      href: '/servicii' 
    },
    { 
      icon: <User className="w-6 h-6" />, 
      label: 'Login', 
      href: '/login' 
    },
    { 
      icon: <Users className="w-6 h-6" />, 
      label: 'Sign Up', 
      href: '/register/worker' 
    },
    { 
      icon: <Menu className="w-6 h-6" />, 
      label: 'Meniu', 
      onClick: () => setIsDrawerOpen(true)
    }
  ];

  // Acțiuni pentru useri autentificați
  const authActions = [
    { 
      icon: <Home className="w-6 h-6" />, 
      label: 'Acasă', 
      href: '/' 
    },
    { 
      icon: <LayoutDashboard className="w-6 h-6" />, 
      label: 'Dashboard', 
      onClick: () => {
        const dashboardRoute = user.user_metadata.role === 'client' 
          ? '/dashboard/client' 
          : '/dashboard/worker';
        router.push(dashboardRoute);
      }
    },
    { 
      icon: <Bell className="w-6 h-6" />, 
      label: 'Notificări', 
      href: '/notifications',
      badge: unreadCount
    },
    { 
      icon: <MessageCircle className="w-6 h-6" />, 
      label: 'Mesaje', 
      href: '/messages',
      badge: unreadMessagesCount
    },
    { 
      icon: <Menu className="w-6 h-6" />, 
      label: 'Meniu', 
      onClick: () => setIsDrawerOpen(true)
    }
  ];

  const currentActions = user ? authActions : guestActions;

  return (
    <div className="md:hidden">
      {/* Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-gray-900 border-t border-gray-800 shadow-lg z-40">
        <div className="grid grid-cols-5 h-16">
          {currentActions.map((action, index) => (
            <NavButton 
              key={index}
              {...action}
              badge={action.badge}
            />
          ))}
        </div>
      </nav>

      {/* Drawer Menu */}
      <AnimatePresence>
        {isDrawerOpen && (
          <>
            {/* Overlay */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsDrawerOpen(false)}
              className="fixed inset-0 bg-black/50 z-40"
              transition={{ duration: 0.15 }}
            />

            {/* Drawer */}
            <motion.div
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ 
                type: 'spring',
                damping: 25,
                stiffness: 300,
                mass: 0.8
              }}
              className="fixed bottom-0 left-0 right-0 bg-gray-900 rounded-t-[32px] z-50"
              style={{ maxHeight: '75vh' }}
            >
              {/* Drawer Handle */}
              <motion.div 
                className="sticky top-0 left-0 right-0 bg-gray-900 pt-3 pb-2 px-4 z-10 
                           rounded-t-[32px] border-b border-gray-800"
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                dragElastic={0.2}
                dragMomentum={false}
                onDragEnd={(_, info) => {
                  if (info.offset.y > 100) {
                    setIsDrawerOpen(false);
                  }
                }}
              >
                <div className="w-12 h-1 bg-gray-700 rounded-full mx-auto cursor-grab active:cursor-grabbing" />
              </motion.div>

              {/* Scrollable Content */}
              <div 
                className="overflow-y-auto overflow-x-hidden" 
                style={{ maxHeight: 'calc(75vh - 48px)' }}
              >
                {/* User Profile Section */}
                {user && (
                  <div className="flex items-center space-x-4 my-4 p-3 bg-gray-800/50 
                                rounded-2xl backdrop-blur-sm mx-4">
                    <div className="w-10 h-10 bg-gray-700 rounded-full flex items-center justify-center">
                      <User className="w-5 h-5 text-white" />
                    </div>
                    <div>
                      <div className="font-semibold text-white">{user.user_metadata?.name || 'User'}</div>
                      <div className="text-sm text-gray-300">{user.email}</div>
                    </div>
                  </div>
                )}

                {/* Menu Categories */}
                <div className="space-y-4 p-4 pb-24">
                  {renderMenuCategories().map((category, idx) => (
                    <div key={idx} className="space-y-2">
                      <div className="px-2 py-1 text-sm font-medium text-gray-400">
                        {category.title}
                      </div>
                      {category.items.map((item, index) => (
                        <Link
                          key={index}
                          href={item.href}
                          className="flex items-center space-x-3 px-4 py-3 hover:bg-gray-800 rounded-xl text-white transition-colors"
                          onClick={() => setIsDrawerOpen(false)}
                        >
                          <item.icon className="w-5 h-5" />
                          <span>{item.name}</span>
                        </Link>
                      ))}
                    </div>
                  ))}

                  {/* User-specific links */}
                  {user && (
                    <div className="space-y-2 mt-6">
                      <div className="px-2 py-1 text-sm font-medium text-gray-400">
                        Cont
                      </div>
                      <Link
                        href="/profil"
                        className="flex items-center space-x-3 px-4 py-3 hover:bg-gray-800 rounded-xl text-white transition-colors"
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <User className="w-5 h-5" />
                        <span>Profil</span>
                      </Link>
                      <Link
                        href="/setari"
                        className="flex items-center space-x-3 px-4 py-3 hover:bg-gray-800 rounded-xl text-white transition-colors"
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <Settings className="w-5 h-5" />
                        <span>Setări</span>
                      </Link>
                      <button
                        onClick={async () => {
                          try {
                            await signOut();
                            setIsDrawerOpen(false);
                            router.push('/');
                          } catch (error) {
                            console.error('Error signing out:', error);
                          }
                        }}
                        className="flex items-center space-x-3 px-4 py-3 w-full text-left hover:bg-red-500/20 rounded-xl text-red-200 transition-colors"
                      >
                        <LogOut className="w-5 h-5" />
                        <span>Deconectare</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

const NavButton = ({ icon, label, href, onClick, badge }) => {
  const content = (
    <div className="relative flex flex-col items-center justify-center h-full">
      <div className="relative text-white">
        {icon}
        {badge > 0 && (
          <span className="absolute -top-2 -right-2 bg-gray-200 text-gray-900 text-xs rounded-full h-5 w-5 flex items-center justify-center font-medium">
            {badge}
          </span>
        )}
      </div>
      <span className="text-xs mt-1 text-gray-300">{label}</span>
    </div>
  );

  if (href) {
    return (
      <Link 
        href={href} 
        className="hover:bg-gray-800 active:bg-gray-700 transition-colors duration-200"
      >
        {content}
      </Link>
    );
  }

  return (
    <button 
      onClick={onClick} 
      className="w-full hover:bg-gray-800 active:bg-gray-700 transition-colors duration-200"
    >
      {content}
    </button>
  );
};